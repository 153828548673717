import React from "react"
import { Link } from 'gatsby'
import Layout from "../hoc/Layout"
import classes from "../styles/library.module.styl"

const Library = (props) => {
  return (
    <Layout location={ props.location } pageTitle="Library">
      <div className={ classes.container }>
        <h1>Library</h1>
        <p>
          The term <em>library</em> is used loosly to represent all sort of input material. It is pretty much all surrounding articles/ books at the moment though (plus some videos). There's reading recommendations, books I'm currently going through, and my antilibrary. I'm always open to suggestions! See the <Link to='/about'><em>About</em></Link> for contact info.
        </p>
        <div className={ classes.items }>
          <a href='https://docs.google.com/spreadsheets/d/1wITQz54mpfjoeZ9MG-41pZRyOvLFiWXGZ9G5Niqn-Lc/edit?usp=sharing' target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
            <h2 className={ classes.title }>Book Log</h2>
            <div className={ classes.description }>
              This recently started spreadsheet is where I track how much I read every week.
            </div>
          </a>
          <Link to='/to_consume'>
            <h2 className={ classes.title }>Antilibrary</h2>
            <div className={ classes.description }>
              I'm in the middle of investigating a few particular topics, but what I have on here are a good indicator of what I'm intrigued by.
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Library
